exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arbide-eguna-js": () => import("./../../../src/pages/arbide-eguna.js" /* webpackChunkName: "component---src-pages-arbide-eguna-js" */),
  "component---src-pages-bloke-txapelketa-js": () => import("./../../../src/pages/bloke-txapelketa.js" /* webpackChunkName: "component---src-pages-bloke-txapelketa-js" */),
  "component---src-pages-erropak-js": () => import("./../../../src/pages/erropak.js" /* webpackChunkName: "component---src-pages-erropak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktua-js": () => import("./../../../src/pages/kontaktua.js" /* webpackChunkName: "component---src-pages-kontaktua-js" */),
  "component---src-pages-rokodromoa-js": () => import("./../../../src/pages/rokodromoa.js" /* webpackChunkName: "component---src-pages-rokodromoa-js" */)
}

